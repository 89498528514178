const offers = [
  {
    "srNo": 1,
    "offerPeriod": "1, 3, 5, 6, 8, 10, 12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 100,
    "noOfCoupons": "Unlimited",
    "code": "GCLBHAL100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 2,
    "offerPeriod": "1, 3, 5, 6, 8, 10, 12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 195,
    "noOfCoupons": "Unlimited",
    "code": "GCLBHAL195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 3,
    "offerPeriod": "1, 3, 5, 6, 8, 10, 12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 325,
    "noOfCoupons": "Unlimited",
    "code": "GCLBHAL325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 4,
    "offerPeriod": "4, 11, 18, 21, 25 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 120,
    "noOfCoupons": "Unlimited",
    "code": "GCDABH120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 5,
    "offerPeriod": "4, 11, 18, 21, 25 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 202,
    "noOfCoupons": "Unlimited",
    "code": "GCDABH202",
    "Details": "Rs.202 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 6,
    "offerPeriod": "4, 11, 18, 21, 25 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GCDABH375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 7,
    "offerPeriod": "7, 14, 28 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 450,
    "noOfCoupons": "Unlimited",
    "code": "GRBHAT450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 8,
    "offerPeriod": "7, 14, 28 June 2024",
    "type": "Atta and Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 30000,
    "discount": 630,
    "noOfCoupons": "Unlimited",
    "code": "ATTA630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 9,
    "offerPeriod": "1, 4, 5, 6, 8, 11, 12, 13, 15, 18, 19, 20, 22, 25, 26, 27, 29 June 2024 ",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 100,
    "noOfCoupons": "Unlimited",
    "code": "GCLSA100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 10,
    "offerPeriod": "1, 4, 5, 6, 8, 11, 12, 13, 15, 18, 19, 20, 22, 25, 26, 27, 29 June 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 195,
    "noOfCoupons": "Unlimited",
    "code": "GCLSA195",
    "Details": "Rs. 195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 11,
    "offerPeriod": "1, 4, 5, 6, 8, 11, 12, 13, 15, 18, 19, 20, 22, 25, 26, 27, 29 June 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 325,
    "noOfCoupons": "Unlimited",
    "code": "GCLSA325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 12,
    "offerPeriod": "3, 10, 17, 21, 24 June 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 120,
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 13,
    "offerPeriod": "3, 10, 17, 21, 24 June 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 202,
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM202",
    "Details": "Rs. 202 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 14,
    "offerPeriod": "3, 10, 17, 21, 24 June 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 15,
    "offerPeriod": "7, 14, 28 June 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 450,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCAT450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 16,
    "offerPeriod": "7, 14, 28 June 2024",
    "type": "Atta and Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 30000,
    "discount": 630,
    "noOfCoupons": "Unlimited",
    "code": "ATTA630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 17,
    "offerPeriod": "1, 5, 6, 8, 12, 13, 15, 19, 20, 22, 26, 27, 29 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 170,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 18,
    "offerPeriod": "1, 5, 6, 8, 12, 13, 15, 19, 20, 22, 26, 27, 29 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 300,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA300",
    "Details": "Rs.170 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 19,
    "offerPeriod": "1, 5, 6, 8, 12, 13, 15, 19, 20, 22, 26, 27, 29 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 575,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 20,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024 ",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 190,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 21,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 330,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 22,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 625,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 23,
    "offerPeriod": "3, 7, 10, 14, 24, 28 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 24,
    "offerPeriod": "3, 7, 10, 14, 24, 28 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 725,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA725",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 25,
    "offerPeriod": "3, 7, 10, 14, 24, 28 June 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 35000,
    "discount": 1015,
    "noOfCoupons": "Unlimited",
    "code": "GRBHDA1015",
    "Details": "Rs.1015 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "26.8.",
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 170,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 27,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 300,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA300",
    "Details": "Rs. 300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 28,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 575,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 29,
    "offerPeriod": "3,4,5,7,10,11,12,14, 17,18, 19, 21, 24,25, 26, 28 June 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 190,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 30,
    "offerPeriod": "3,4,5,7,10,11,12,14, 17,18, 19, 21, 24,25, 26, 28 June 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 330,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA330",
    "Details": "Rs. 330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 31,
    "offerPeriod": "3,4,5,7,10,11,12,14, 17,18, 19, 21, 24,25, 26, 28 June 2024",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 625,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 32,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 33,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 725,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA725",
    "Details": "Rs.725 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 34,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Dal and Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 35000,
    "discount": 1015,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCDA1015",
    "Details": "Rs.1015 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 35,
    "offerPeriod": "1, 3 to 8, 10 to 15, 17 to 22, 24 to 29 June 2024 ",
    "type": "Dry Fruit and Spices",
    "location": "All cities",
    "mov": 1000,
    "discount": 20,
    "noOfCoupons": "Unlimited",
    "code": "GSF20",
    "Details": "Rs.20 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 36,
    "offerPeriod": "1, 3 to 8, 10 to 15, 17 to 22, 24 to 29 June 2024",
    "type": "Dry Fruit and Spices",
    "location": "All cities",
    "mov": 2000,
    "discount": 45,
    "noOfCoupons": "Unlimited",
    "code": "GSF45",
    "Details": "Rs.45 off on orders worth Rs. 2,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 37,
    "offerPeriod": "1, 3 to 8, 10 to 15, 17 to 22, 24 to 29 June 2024 ",
    "type": "Dry Fruit and Spices",
    "location": "All cities",
    "mov": 3000,
    "discount": 75,
    "noOfCoupons": "Unlimited",
    "code": "GSF75",
    "Details": "Rs.75 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 38,
    "offerPeriod": "1, 5,6, 8, 12,13, 15, 19, 20, 22, 26, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 3000,
    "discount": 42,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 39,
    "offerPeriod": "1, 5,6, 8, 12,13, 15, 19, 20, 22, 26, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 5000,
    "discount": 75,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 40,
    "offerPeriod": "1, 5,6, 8, 12,13, 15, 19, 20, 22, 26, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 170,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 41,
    "offerPeriod": "1, 5,6, 8, 12,13, 15, 19, 20, 22, 26, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 300,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 42,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 3000,
    "discount": 51,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 43,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 5000,
    "discount": 90,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 44,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 190,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 45,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 330,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 46,
    "offerPeriod": "4, 11, 17, 18, 21, 25 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 625,
    "noOfCoupons": "Unlimited",
    "code": "GRBHFM625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 47,
    "offerPeriod": "3, 7, 10, 14, 24, 28 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "RDSMS375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 48,
    "offerPeriod": "3, 7, 10, 14, 24, 28 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 725,
    "noOfCoupons": "Unlimited",
    "code": "RDSMS725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 49,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 3000,
    "discount": 42,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 50,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 5000,
    "discount": 75,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 51,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 170,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 52,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 300,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 53,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 3000,
    "discount": 51,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM`51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 54,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 5000,
    "discount": 90,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 55,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 190,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 56,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 330,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 57,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 625,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 58,
    "offerPeriod": "Not Scheduled Currently",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 59,
    "offerPeriod": "Not Scheduled Currently",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 725,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCFM725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 60,
    "offerPeriod": "1, 3 to 8, 10 to 15, 17 to 22, 24 to 29 June 2024 ",
    "type": "General Merchandising",
    "location": "All cities",
    "mov": 1000,
    "discount": 30,
    "noOfCoupons": "Unlimited",
    "code": "GRGM30",
    "Details": "Rs.30 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 61,
    "offerPeriod": "1, 3 to 8, 10 to 15, 17 to 22, 24 to 29 June 2024",
    "type": "General Merchandising",
    "location": "All cities",
    "mov": 2000,
    "discount": 80,
    "noOfCoupons": "Unlimited",
    "code": "GRGM80",
    "Details": "Rs.80 off on orders worth Rs. 2,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 62,
    "offerPeriod": "1, 3 to 8, 10 to 15, 17 to 22, 24 to 29 June 2024 ",
    "type": "General Merchandising",
    "location": "All cities",
    "mov": 3000,
    "discount": 150,
    "noOfCoupons": "Unlimited",
    "code": "GRGM150",
    "Details": "Rs.150 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 63,
    "offerPeriod": "3, 5,6,8, 10,12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 105,
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 64,
    "offerPeriod": "3, 5,6,8, 10,12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 20000,
    "discount": 140,
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOL140",
    "Details": "Rs.140 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 65,
    "offerPeriod": "3, 5,6,8, 10,12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 175,
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 66,
    "offerPeriod": "4, 11, 18, 21, 25 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 195,
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 67,
    "offerPeriod": "4, 11, 18, 21, 25 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 20000,
    "discount": 260,
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOM260",
    "Details": "Rs.260 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 68,
    "offerPeriod": "4, 11, 18, 21, 25 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 325,
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 69,
    "offerPeriod": "7, 14, 28 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GRBHOI375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 70,
    "offerPeriod": "7, 14, 28 June 2024",
    "type": "Edible Oils",
    "location": "Bangalore, Hyderabad",
    "mov": 30000,
    "discount": 450,
    "noOfCoupons": "Unlimited",
    "code": "SCJO450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 71,
    "offerPeriod": "3, 5, 6, 8, 10, 12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 70,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI70",
    "Details": "Rs.70 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 72,
    "offerPeriod": "3, 5, 6, 8, 10, 12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 105,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 73,
    "offerPeriod": "3, 5, 6, 8, 10, 12, 13, 15, 17, 19, 20, 22, 24, 26, 27, 29 June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 175,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 74,
    "offerPeriod": "18, 21 June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 180,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI180",
    "Details": "Rs.180 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 75,
    "offerPeriod": "18, 21 June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 20000,
    "discount": 250,
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM250",
    "Details": "Rs.250 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 76,
    "offerPeriod": "18, 21 June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 30000,
    "discount": 390,
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM390",
    "Details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 77,
    "offerPeriod": "4, 7, 11, 14, 25, 28, June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOI375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 78,
    "offerPeriod": "4, 7, 11, 14, 25, 28, June 2024",
    "type": "Edible Oils",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 30000,
    "discount": 450,
    "noOfCoupons": "Unlimited",
    "code": "SCJO450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 79,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 5000,
    "discount": 40,
    "noOfCoupons": "Unlimited",
    "code": "GRBHOS40",
    "Details": "Rs.40 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 80,
    "offerPeriod": "1, 3 to 8, 10-15, 17-22, 24-29 June 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Bangalore, Hyderabad",
    "mov": 5000,
    "discount": 50,
    "noOfCoupons": "Unlimited",
    "code": "GRBHOS50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 81,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 5000,
    "discount": 40,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOS40",
    "Details": "Rs.40 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 82,
    "offerPeriod": "1, 3 to 8, 10-15, 17-22, 24-29 June 2024 June 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 5000,
    "discount": 50,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCOS50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 83,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 100,
    "noOfCoupons": "Unlimited",
    "code": "GDSR100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 84,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 195,
    "noOfCoupons": "Unlimited",
    "code": "GDSR195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 85,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GDSR375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 86,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 35000,
    "discount": 560,
    "noOfCoupons": "Unlimited",
    "code": "GDSR560",
    "Details": "Rs.560 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 87,
    "offerPeriod": "4, 5, 11, 12, 17, 18, 19, 21, 25, 26 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 10000,
    "discount": 175,
    "noOfCoupons": "Unlimited",
    "code": "GDSRM175",
    "Details": "Rs.175 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 88,
    "offerPeriod": "4, 5, 11, 12, 17, 18, 19, 21, 25, 26 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 278,
    "noOfCoupons": "Unlimited",
    "code": "GDSRM278",
    "Details": "Rs.278 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 89,
    "offerPeriod": "4, 5, 11, 12, 17, 18, 19, 21, 25, 26 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 488,
    "noOfCoupons": "Unlimited",
    "code": "GDSRM488",
    "Details": "Rs.488 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 90,
    "offerPeriod": "4, 5, 11, 12, 17, 18, 19, 21, 25, 26 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 35000,
    "discount": 700,
    "noOfCoupons": "Unlimited",
    "code": "GDSRM700",
    "Details": "Rs.700 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 91,
    "offerPeriod": "3,7, 10, 14, 24, 29 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 315,
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 92,
    "offerPeriod": "3,7, 10, 14, 24, 29 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 550,
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 93,
    "offerPeriod": "3,7, 10, 14, 24, 29 June 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore, Hyderabad",
    "mov": 35000,
    "discount": 875,
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 94,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 100,
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 95,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 195,
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 96,
    "offerPeriod": "1, 6, 8, 13, 15, 20, 22, 27, 29 June 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 375,
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 97,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 10000,
    "discount": 185,
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM185",
    "Details": "Rs.185 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 98,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 293,
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM293",
    "Details": "Rs.293 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 99,
    "offerPeriod": "3,4, 5, 7, 10, 11, 12, 14, 17, 18, 19, 21, 24, 25, 26, 28 June 2024",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 500,
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM500",
    "Details": "Rs.500 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 100,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 315,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 101,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 550,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 102,
    "offerPeriod": "Not Scheduled Currently",
    "type": "Rice and Cereals",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 35000,
    "discount": 875,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 103,
    "offerPeriod": "1, 17 to 22 June 2024 ",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 5000,
    "discount": 50,
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 104,
    "offerPeriod": "3 to 8, 10 to 15, 24 to 29 June 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Bangalore, Hyderabad",
    "mov": 5000,
    "discount": 75,
    "noOfCoupons": "Unlimited",
    "code": "GRBHRI75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 105,
    "offerPeriod": "1, 17 to 22 June 2024 ",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 5000,
    "discount": 50,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 106,
    "offerPeriod": "3 to 8, 10 to 15, 24 to 29 June 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 5000,
    "discount": 75,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCRI75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 107,
    "offerPeriod": "3, 4, 6, 8, 10,11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 12000,
    "discount": 84,
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHS84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 108,
    "offerPeriod": "3, 4, 6, 8, 10,11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 105,
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 109,
    "offerPeriod": "3, 4, 6, 8, 10,11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 20000,
    "discount": 140,
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHS147",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 110,
    "offerPeriod": "5, 12, 19, 21, 26 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 15000,
    "discount": 165,
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 111,
    "offerPeriod": "5, 12, 19, 21, 26 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 300,
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 112,
    "offerPeriod": "5, 12, 19, 21, 26 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 35000,
    "discount": 455,
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 113,
    "offerPeriod": "1, 7, 14, 28 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 25000,
    "discount": 388,
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU338",
    "Details": "Rs.388 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 114,
    "offerPeriod": "1, 7, 14, 28 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore, Hyderabad",
    "mov": 35000,
    "discount": 525,
    "noOfCoupons": "Unlimited",
    "code": "GRBHSU525",
    "Details": "Rs.525 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 115,
    "offerPeriod": "3, 4, 6, 8, 10,11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 12000,
    "discount": 84,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 116,
    "offerPeriod": "3, 4, 6, 8, 10,11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 105,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU105",
    "Details": "Rs.105 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 117,
    "offerPeriod": "3, 4, 6, 8, 10,11, 13, 15, 17, 18, 20, 22, 24, 25, 27, 29 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 20000,
    "discount": 140,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU140",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 118,
    "offerPeriod": "5, 12, 19, 21, 26 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 15000,
    "discount": 165,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 119,
    "offerPeriod": "5, 12, 19, 21, 26 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 300,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 120,
    "offerPeriod": "5, 12, 19, 21, 26 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 35000,
    "discount": 455,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 121,
    "offerPeriod": "1, 7, 14, 28 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 25000,
    "discount": 338,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU338",
    "Details": "Rs.338 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": 122,
    "offerPeriod": "1, 7, 14, 28 June 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi, Gurgaon, Chennai",
    "mov": 35000,
    "discount": 525,
    "noOfCoupons": "Unlimited",
    "code": "GRDGCSU525",
    "Details": "Rs.525 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
]


export default offers;